import { z } from 'zod';

// +----------+
// | Response |
// +----------+

//  details section

const DashboardInsightDetailsTableDataHeaderModelSchema = z.object({
  name: z.string(),
  type: z.string(),
});

export const DashboardInsightDetailsTableDataModelSchema = z.object({
  headers: z.array(DashboardInsightDetailsTableDataHeaderModelSchema),
  data: z.array(z.array(z.any())),
});

const DashboardInsightDetailsReportInfoModelSchema = z.object({
  riskLevel: z.enum(['warning', 'critical']),
  description: z.string(),
  businessImpact: z.string(),
  recommendation: z.string(),
});

// Final payload
export const DashboardDetailsModelSchema = z.object({
  reportInfo: DashboardInsightDetailsReportInfoModelSchema,
  tableData: DashboardInsightDetailsTableDataModelSchema,
});

export type DashboardDetailsModel = z.infer<typeof DashboardDetailsModelSchema>;
