import { z } from 'zod';

import { DashboardDetailsModelSchema, DashboardInsightDetailsTableDataModelSchema } from './details.model';

const DashboardInsightDetailsTableDataRowViewModelSchema = z
  .object({
    id: z.string(),
  })
  .catchall(z.string());

type DashboardInsightDetailsTableDataRowViewModel = z.infer<typeof DashboardInsightDetailsTableDataRowViewModelSchema>;

const DashboardInsightDetailsTableDataViewModelSchema = DashboardInsightDetailsTableDataModelSchema.extend({
  data: z.array(DashboardInsightDetailsTableDataRowViewModelSchema),
});

const DashboardDetailsViewModelSchema = DashboardDetailsModelSchema.extend({
  tableData: DashboardInsightDetailsTableDataViewModelSchema,
});

export type DashboardDetailsViewModel = z.infer<typeof DashboardDetailsViewModelSchema>;

export function dashboardDetailsModelToViewModel(input: unknown): DashboardDetailsViewModel {
  const model = DashboardDetailsModelSchema.parse(input);

  return {
    reportInfo: model.reportInfo,
    tableData: {
      headers: model.tableData.headers,
      data: model.tableData.data.map((row, index) => {
        const newRow: DashboardInsightDetailsTableDataRowViewModel = {
          id: index.toString(),
        };

        model.tableData.headers.forEach((header, index) => {
          newRow[header.name] = row[index];
        });

        return newRow;
      }),
    },
  };
}
