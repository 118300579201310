import { z } from 'zod';

import { completeTimeId, DashboardStatsModelSchema, tagsCountId, tmsFoundId } from './stats.model';

export const DashboardStatsViewModelSchema = z.object({
  tmsFound: z.array(z.string()).default([]),
  tagsFound: z.number().optional(),
  completePageLoadTime: z.number().optional(),
  perceivedPageLoadTime: z.number().optional(),
});

export type DashboardStatsViewModel = z.infer<typeof DashboardStatsViewModelSchema>;

export function dashboardStatsModelToViewModel(input: unknown) {
  const model = DashboardStatsModelSchema.parse(input);

  const viewModel: DashboardStatsViewModel = {
    tmsFound: model[tmsFoundId]?.data.flat() ?? [],
    tagsFound: model[tagsCountId]?.data?.[0]?.[0] !== undefined ? +model[tagsCountId].data[0][0] : undefined,
    completePageLoadTime: +model[completeTimeId]?.data[0][0].slice(0, -1) ?? undefined,
    perceivedPageLoadTime: +model[completeTimeId]?.data[0][0].slice(0, -1) ?? undefined,
  };

  return viewModel;
}
