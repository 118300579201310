import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React, { createContext, ReactNode, startTransition, useContext, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore this package has no typescript equivalents.
import LockScreen from 'react-lock-screen';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { APP_URLS } from '../config/api-urls';
import { AppRoutes } from '../config/app-routes';
import { CognitoUser, LoginProps, Profile } from '../types';
import { useAxios } from '../utils/transport/useAxios';

interface AuthContextType {
  user?: CognitoUser;
  loading: boolean;
  error?: any;
  login: (credentials: LoginProps) => void;
  loginSSO: (code: string) => Promise<void>;
  logout: () => void;
  setError: (value: any) => void;
  setUserToApp: (user: CognitoUser) => void;
  userProfile?: Profile;
  setUserProfile: (profile: Profile) => void;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

/**
 * Export the provider as we need to wrap the entire app with it
 * @function
 * @name AuthProvider
 * @param {object} children - child components
 * @return {functions} state managment and html event handler
 */
export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const [user, setUser] = useState<CognitoUser>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [userProfile, setUserProfile] = useState<Profile>();

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const axios = useAxios();
  const authRoutes = ['/login', '/singup', '/forgot-password', '/activate-user'];

  /**
   * set local storage for user data (login)
   * @function
   * @name setUserToApp
   * @param {CognitoUser} data - cognito user data
   * @return {void}
   */
  const setUserToApp = (data: CognitoUser) => {
    setUser(data);
    const redirect = searchParams.get('redirect');
    let url: string = AppRoutes.DASHBOARD;
    if (redirect && !`/${redirect}`.startsWith(AppRoutes.LOGIN)) {
      try {
        new URL(redirect);
      } catch (e) {
        // expect it to fail because it should be a relative url.
        url = redirect;
      }
    }
    startTransition(() => navigate(url));
  };

  const _logout = async () => {
    await axios.post(
      APP_URLS.userLogout(),
      {},
      {
        withCredentials: true,
      }
    );

    window.localStorage.removeItem('email');

    startTransition(() => {
      setUser(undefined);
      navigate('/login');
    });
  };

  /**
   * unset local storage for user data (logout)
   * @function
   * @name logout
   * @return {void}
   */
  const logout = () => _logout();

  useEffect(() => {
    setLoadingInitial(true);
    setError(undefined);
    axios
      .get<CognitoUser>(APP_URLS.currentUser(), { withCredentials: true })
      .then(({ data: user }) => {
        setUser(user);
        authRoutes.indexOf(location.pathname) > -1 && startTransition(() => navigate(AppRoutes.DASHBOARD));
      })
      .catch((_) => {
        return;
      })
      .finally(() => setLoadingInitial(false));
    // eslint-disable-next-line
  }, [axios]);

  /**
   * login function
   * @function
   * @name login
   * @param {object} loginData - LoginProps object
   * @return {void}
   */
  const login = async (loginData: LoginProps) => {
    setLoading(true);
    setError(undefined);
    try {
      const { data } = await axios.post<CognitoUser>(APP_URLS.userLogin(), loginData, {
        withCredentials: true,
      });
      setUserToApp(data);
      window.localStorage.cachedLoginSSOUser = JSON.stringify({
        type: 'standard',
      });
    } catch (e) {
      if (e == 'Error: Network Error') {
        setError({
          message: 'Too many failed password attempts, try again in 1 minute',
        });
      } else {
        setError(e);
      }
    } finally {
      setLoading(false);
    }
  };

  const loginSSO = async (code: string) => {
    setLoading(true);
    setError(undefined);
    try {
      const { data } = await axios.get(APP_URLS.userSSOLogin(code));
      setUserToApp(data);
    } catch (e) {
      throw e;
    }
    setLoading(false);
  };

  if (loadingInitial) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingInitial}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider
      value={{
        user,
        userProfile,
        setUserProfile,
        setUserToApp,
        loading,
        login,
        loginSSO,
        error,
        logout,
        setError,
      }}
    >
      <LockScreen
        timeout={15 * 60 * 1000}
        ui={(setLock: (arg0: boolean) => void) => {
          if (user && window.location.pathname !== AppRoutes.LOGIN) {
            const alertDelay = 120;
            const Countdown = () => {
              const startTime = useRef(Date.now() / 1000);
              const displayTime = useRef(alertDelay);
              const maxTime = startTime.current + alertDelay;
              const [, setCounter] = useState(0);
              const refresh = () => setCounter((counter) => counter + 1);
              useEffect(() => {
                const timer = setInterval(() => {
                  if (Date.now() / 1000 > maxTime) {
                    logout();
                    setLock(false);
                  } else {
                    displayTime.current = Math.floor(maxTime - Date.now() / 1000);
                    refresh();
                  }
                }, 1000);
                return () => clearInterval(timer);
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, []);
              return (
                <Typography>
                  {Math.floor(displayTime.current / 60)}:{(displayTime.current % 60).toString().padStart(2, '0')}
                </Typography>
              );
            };
            return (
              <Dialog open={true}>
                <DialogTitle>You will be logged out in 2 minutes due to inactivity.</DialogTitle>
                <DialogActions>
                  <Stack flexGrow={1} paddingX={2}>
                    <Countdown />
                  </Stack>
                  <Button autoFocus onClick={() => setLock(false)}>
                    Stay Logged In
                  </Button>
                </DialogActions>
              </Dialog>
            );
          } else {
            setLock(false);
          }
          return <div></div>;
        }}
      >
        <React.Suspense>{children}</React.Suspense>
      </LockScreen>
    </AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
