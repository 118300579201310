import { ClickHouseApiResponseSchemaBuilder } from 'api/pages/schemas/clickhouse';
import { z } from 'zod';

// +----------+
// | Response |
// +----------+

//  tms_found section
export const tmsFoundId = 'TM5fnd';
export const DashboardStatsTmsFoundModelSchema = ClickHouseApiResponseSchemaBuilder(['tms_found']);
export type DashboardStatsTmsFoundModel = z.infer<typeof DashboardStatsTmsFoundModelSchema>;

//  tags_count section
export const tagsCountId = 'tgF0ND';
export const DashboardStatsTagsCountModelSchema = ClickHouseApiResponseSchemaBuilder(['tags_count']);
export type DashboardStatsTagsCountModel = z.infer<typeof DashboardStatsTagsCountModelSchema>;

//  Average perceived page load time
export const perceivedTimeId = '4GpcTm';
export const DashboardStatsPerceivedTimeIdModelSchema = ClickHouseApiResponseSchemaBuilder(['time_in_seconds']);
export type DashboardStatsPerceivedTimeIdModel = z.infer<typeof DashboardStatsPerceivedTimeIdModelSchema>;

//  Average complete page load time
export const completeTimeId = 'agCm1T';
export const DashboardStatsCompleteTimeModelSchema = ClickHouseApiResponseSchemaBuilder(['time_in_seconds']);
export type DashboardStatsCompleteTimeModel = z.infer<typeof DashboardStatsCompleteTimeModelSchema>;

// Final payload

export const DashboardStatsModelSchema = z.object({
  [tmsFoundId]: DashboardStatsTmsFoundModelSchema.optional(),
  [tagsCountId]: DashboardStatsTagsCountModelSchema.optional(),
  [perceivedTimeId]: DashboardStatsPerceivedTimeIdModelSchema.optional(),
  [completeTimeId]: DashboardStatsCompleteTimeModelSchema.optional(),
});

export type DashboardStatsModel = z.infer<typeof DashboardStatsModelSchema>;
