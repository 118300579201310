import { useAxios } from 'api';
import routes from 'api/routes';
import useSWR from 'swr';

import { ClickHouseApiQueryVariables, ClickHouseApiQueryVariablesSchema } from '../schemas/clickhouse';

import { dashboardDetailsModelToViewModel } from './schemas/insights/details/details.viewmodel';
import { dashboardInsightsModelToViewModel } from './schemas/insights/leftnav/leftnav.viewmodel';
import { dashboardStatsModelToViewModel } from './schemas/stats/stats.viewmodel';

export function useDashboardStats(queryVariables: ClickHouseApiQueryVariables) {
  const { post } = useAxios();

  const payloadValid = ClickHouseApiQueryVariablesSchema.safeParse(queryVariables).success;

  return useSWR(
    payloadValid ? JSON.stringify({ url: routes.dashboard.stats(), payload: queryVariables }) : null,
    async (json) => {
      const { url } = JSON.parse(json);
      const response = await post(url, queryVariables, {
        withCredentials: true,
      });

      const data = dashboardStatsModelToViewModel(response.data);

      return data;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      errorRetryCount: 1,
      // refreshInterval: 1000 * 60, // 1 minute
    }
  );
}

export function useDashboardInsights(queryVariables: ClickHouseApiQueryVariables) {
  const { post } = useAxios();

  const payloadValid = ClickHouseApiQueryVariablesSchema.safeParse(queryVariables).success;

  return useSWR(
    payloadValid ? JSON.stringify({ url: routes.dashboard.insights(), payload: queryVariables }) : null,
    async (json) => {
      const { url } = JSON.parse(json);
      const response = await post(url, queryVariables, {
        withCredentials: true,
      });

      const data = dashboardInsightsModelToViewModel(response.data);

      return data;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      errorRetryCount: 1,
      // refreshInterval: 1000 * 60, // 1 minute
    }
  );
}

export function useDashboardDetails(id: string | null, queryVariables: ClickHouseApiQueryVariables) {
  const { post } = useAxios();

  const payloadValid = id && ClickHouseApiQueryVariablesSchema.safeParse(queryVariables).success;

  return useSWR(
    payloadValid ? JSON.stringify({ url: routes.dashboard.details(id), payload: queryVariables }) : null,
    async (json) => {
      const { url } = JSON.parse(json);
      const response = await post(url, queryVariables, {
        withCredentials: true,
      });

      const data = dashboardDetailsModelToViewModel(response.data);

      return data;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      errorRetryCount: 1,
      // refreshInterval: 1000 * 60, // 1 minute
    }
  );
}
