import { ClickHouseApiResponseSchemaBuilder } from 'api/pages/schemas/clickhouse';
import { z } from 'zod';

// +----------+
// | Response |
// +----------+

//  left nav section
export const leftNavId = 'lftN4V';

export const DashboardInsightsLeftNavHeaders = [
  'query_variables',
  'title',
  'insight',
  'risk_level',
  'category_friendly_name',
  'report_summary_name',
  'last_seen',
] as const;
export type DashboardInsightsLeftNavHeaderKeys = (typeof DashboardInsightsLeftNavHeaders)[number];

export const DashboardInsightsLeftNavModelSchema = ClickHouseApiResponseSchemaBuilder(DashboardInsightsLeftNavHeaders);

export type DashboardInsightsLeftNavModel = z.infer<typeof DashboardInsightsLeftNavModelSchema>;

// Final payload

export const DashboardInsightsModelSchema = z.object({
  [leftNavId]: DashboardInsightsLeftNavModelSchema.optional(),
});

export type DashboardInsightsModel = z.infer<typeof DashboardInsightsModelSchema>;
