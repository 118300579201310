import { z } from 'zod';

import { StringNumberSchema } from '../const/stringNumber';
import { EnvironmentIdSchema, NewTagIdSchema } from '../id';

import { Dimensions } from './dimensions';

export const DataExportDimensionIdSchema = z.string().brand('DataExportDimensionId');
export type DataExportDimensionId = z.infer<typeof DataExportDimensionIdSchema>;

export const DataExportMetricIdSchema = z.string().brand('DataExportMetricId');
export type DataExportMetricId = z.infer<typeof DataExportMetricIdSchema>;

/*
 * =============
 * = UI Models =
 * =============
 */

export const ApiCreateDimensionPropertyPathSchema = z.enum(['dimensions', 'tags.variableConfigs']);

export type ApiCreateDimensionPropertyPath = z.infer<typeof ApiCreateDimensionPropertyPathSchema>;

// Common fields for UI interop (not anything data wise)
export const ApiCreateCommonDimensionPropertiesSchema = z.object({
  id: DataExportDimensionIdSchema,
  friendlyName: z.string(),
  source: ApiCreateDimensionPropertyPathSchema,
  group: z.string().optional(),
  type: z.string().optional(),
  meta: z.any().optional(),
  allowedInSegmentation: z.boolean().optional(),
});

export type ApiCreateCommonDimensionProperties = z.infer<typeof ApiCreateCommonDimensionPropertiesSchema>;

// Main id is dimensionId,
// UI also adds in an id and friendlyName field but that is only to render in the UI
export const ApiCreateStandardDimensionSchema = ApiCreateCommonDimensionPropertiesSchema.extend({
  eventType: z.string().optional(),
  dimensionId: DataExportDimensionIdSchema,
  source: ApiCreateDimensionPropertyPathSchema.extract(['dimensions']),
});

export type ApiCreateStandardDimension = z.infer<typeof ApiCreateStandardDimensionSchema>;

// See above notes
export const ApiCreateTagVariableDimensionSchema = ApiCreateCommonDimensionPropertiesSchema.extend({
  tagId: z.string(),
  tagFriendlyName: z.string(),
  variableId: DataExportDimensionIdSchema,
  variablePath: z.string().optional(),
  category: z.string(),
  source: ApiCreateDimensionPropertyPathSchema.extract(['tags.variableConfigs']),
  eventDimension: ApiCreateStandardDimensionSchema.optional(),
});

export type ApiCreateTagVariableDimension = z.infer<typeof ApiCreateTagVariableDimensionSchema>;

export const ApiCreateDimensionSchema = z.union([
  ApiCreateStandardDimensionSchema,
  ApiCreateTagVariableDimensionSchema,
]);

export type ApiCreateDimension = z.infer<typeof ApiCreateDimensionSchema>;

/*
 * ==============
 * = API Models =
 * ==============
 */

export const ApiDataExportStandardDimensionSchema = z
  .object({
    id: DataExportDimensionIdSchema,
    friendlyName: z.string(),
    type: z.string().optional(),
    column: z.string().optional(),
    eventType: z.string().optional(),
    uiGrouping: z.string().optional(),
    uiFlags: z
      .object({
        showInSegments: z.boolean().optional(),
        showInDimensions: z.boolean().optional(),
      })
      .optional(),
  })
  .transform((data) => {
    const output: ApiCreateStandardDimension = {
      id: data.id,
      dimensionId: data.id,
      friendlyName: data.friendlyName,
      source: 'dimensions',
      type: data.type ?? 'Any',
      group: data.uiGrouping ?? 'Dimensions',
      allowedInSegmentation: data.uiFlags?.showInSegments ?? true,
      eventType: data.eventType,
    };

    return output;
  });

export type ApiDataExportDimension = z.infer<typeof ApiDataExportStandardDimensionSchema>;

export const ApiDataExportTagVariableDimensionSchema = z.object({
  _id: NewTagIdSchema,
  friendlyName: z.string(),
  logoUrl: z.string().optional(),
  oldId: z.string(),
  variables: z.array(
    z.object({
      id: DataExportDimensionIdSchema,
      friendlyName: z.string(),
      technicalName: z.string().optional(),
      categoryFriendlyName: z.string(),
      propertyPath: z.string(),
      tagId: z.string().optional(),
    })
  ),
});

export type ApiDataExportTag = z.infer<typeof ApiDataExportTagVariableDimensionSchema>;

export const ApiDataExportMetricSchema = z
  .object({
    id: DataExportMetricIdSchema,
    friendlyName: z.string(),
  })
  .transform((data) => ({
    metricId: data.id,
    friendlyName: data.friendlyName,
  }));

export type ApiDataExportMetric = z.infer<typeof ApiDataExportMetricSchema>;

export const ApiDataExportDimensionsAndMetricsSchema = z
  .object({
    tags: z.array(ApiDataExportTagVariableDimensionSchema),
    metrics: z.array(ApiDataExportMetricSchema),
    dimensions: z.array(ApiDataExportStandardDimensionSchema),
  })
  .transform((data) => {
    const flatTags: ApiCreateTagVariableDimension[] = data.tags.flatMap((tag) =>
      tag.variables.map((variable) => ({
        tagId: tag._id,
        variableId: variable.id,
        source: 'tags.variableConfigs',
        type: 'string',
        tagFriendlyName: tag.friendlyName,
        category: variable.categoryFriendlyName,
        friendlyName: variable.technicalName
          ? `${variable.friendlyName} (${variable.technicalName})`
          : variable.friendlyName,
        id: `${tag._id}.${variable.id}.${variable.propertyPath}` as DataExportDimensionId,
        variablePath: variable.propertyPath,
        allowedInSegmentation: true,
      }))
    );

    const finalDimensions = [...data.dimensions, ...flatTags];

    const combinedData: DataExportDimensionsAndMetrics = {
      ...data,
      dimensions: finalDimensions.map((x) => {
        if (x.source === 'dimensions' && x.eventType) {
          const meta = Dimensions.find((y) => y.id === x.id);
          const group = x.group || meta?.group || (x.source === 'dimensions' ? 'Dimensions' : 'Tag Variables');
          const dimensionAsEvent: ApiCreateTagVariableDimension = {
            id: x.id,
            friendlyName: x.friendlyName,
            category: x.group ?? 'Browser',
            source: 'tags.variableConfigs',
            tagFriendlyName: 'Event',
            tagId: 'event',
            allowedInSegmentation: true,
            variableId: x.dimensionId,
            type: x.type,
            meta,
            group,
            eventDimension: x,
          };

          return dimensionAsEvent;
        }
        const meta =
          x.source === 'tags.variableConfigs' ? { group: x.category } : Dimensions.find((y) => y.id === x.id);
        const group = x.group || meta?.group || (x.source === 'dimensions' ? 'Dimensions' : 'Tag Variables');

        return {
          ...x,
          group,
          meta,
        };
      }),
    };

    return combinedData;
  });

export type ApiDataExportDimensionsAndMetrics = z.infer<typeof ApiDataExportDimensionsAndMetricsSchema>;

export const DataExportDimensionsAndMetricsSchema = z.object({
  metrics: z.array(ApiDataExportMetricSchema),
  dimensions: z.array(ApiCreateDimensionSchema),
  tags: z.array(ApiDataExportTagVariableDimensionSchema),
});

export type DataExportDimensionsAndMetrics = z.infer<typeof DataExportDimensionsAndMetricsSchema>;

export const ApiDataExportPostfixOperandsSchema = z.object({
  propertyPath: ApiCreateDimensionSchema,
  comparator: z.string(),
  ignoreCase: z.boolean().optional(),
  comparisonAttribute: z.string(),
  comparisonValue: z.any(),
});

export type ApiDataExportPostfixOperands = z.infer<typeof ApiDataExportPostfixOperandsSchema>;

export const ApiDataExportPostfixSchema = z.object({
  postfixExpression: z.array(z.union([StringNumberSchema, z.literal('&'), z.literal('|')])),
  operands: z.array(ApiDataExportPostfixOperandsSchema),
  filterLevel: z.enum(['pageview', 'event']),
});

export type ApiDataExportPostfix = z.infer<typeof ApiDataExportPostfixSchema>;

// ====================
// = FINAL ============
// ====================

const ApiDataExportPayloadDimensionSchema = z.union([
  z.object({
    dimensionId: DataExportDimensionIdSchema,
    source: ApiCreateDimensionPropertyPathSchema.extract(['dimensions']),
  }),
  z.object({
    tagId: NewTagIdSchema,
    // variableId: DataExportDimensionIdSchema,
    // variablePath: z.string().optional(),
    dataTypes: z.array(z.enum(['request_data', 'anomaly_data', 'pii_data', 'consent_violations'])).min(1),
    source: ApiCreateDimensionPropertyPathSchema.extract(['tags.variableConfigs']),
  }),
]);

const ApiDataExportPayloadPropertyPathSchema = z.union([
  z.object({
    dimensionId: DataExportDimensionIdSchema,
    source: ApiCreateDimensionPropertyPathSchema.extract(['dimensions']),
  }),
  z.object({
    tagId: NewTagIdSchema,
    variableId: DataExportDimensionIdSchema,
    variablePath: z.string().optional(),
    // dataTypes: z.array(z.enum(['request_data', 'anomaly_data', 'pii_data', 'consent_violations'])),
    source: ApiCreateDimensionPropertyPathSchema.extract(['tags.variableConfigs']),
  }),
]);

export type ApiDataExportPayloadDimension = z.infer<typeof ApiDataExportPayloadDimensionSchema>;

export const ApiDataExportSchema = z.object({
  name: z.string().max(200),
  format: z.enum(['csv', 'json']),
  environmentGuid: EnvironmentIdSchema,
  filters: z.array(
    z.object({
      filterLevel: ApiDataExportPostfixSchema.shape.filterLevel,
      postfixExpression: ApiDataExportPostfixSchema.shape.postfixExpression,
      operands: z.array(
        ApiDataExportPostfixOperandsSchema.extend({
          propertyPath: ApiDataExportPayloadPropertyPathSchema,
          comparisonValue: z
            .any()
            .refine((val) => (val instanceof Error ? false : true))
            .refine((val) => (typeof val === 'string' ? val.length > 0 : true), {
              message: 'A string comparison value must not be empty',
            })
            .refine(
              (val) =>
                Array.isArray(val)
                  ? val.length > 0 && val.every((x) => (typeof x === 'string' ? x.length > 0 : true))
                  : true,
              {
                message: 'An array comparison value must not be empty',
              }
            ),
        })
      ),
    })
  ),
  emails: z.array(z.string().email()).optional(),
  limit: z.number().min(1).max(50000).optional(),
  metrics: z.array(
    z.object({
      metricId: DataExportMetricIdSchema,
    })
  ),
  dimensions: z.array(ApiDataExportPayloadDimensionSchema),
  sorting: z
    .array(
      z.object({
        dimensionId: DataExportDimensionIdSchema,
        order: z.enum(['asc', 'desc']),
      })
    )
    .optional(),
  dateRange: z.object({
    startDate: z.string(),
    endDate: z.string(),
  }),
});

export type ApiDataExport = z.infer<typeof ApiDataExportSchema>;
