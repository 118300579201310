import { ClickHouseDataHeadingType, ClickHouseParseDataHeadingType } from 'api/utils/clickhouse';
import LzString from 'lz-string';
import moment, { Moment } from 'moment';
import { z } from 'zod';

import { DashboardInsightsLeftNavHeaderKeys, DashboardInsightsModelSchema, leftNavId } from './leftnav.model';

const DashboardInsightsLeftNavViewModelSchema = z.object({
  id: z.string(),
  // insightId: z.string(),
  title: z.string(),
  time: z.custom<Moment>((data) => moment.isMoment(data)),
  // tagId: z.string().optional(),
  category: z.string(),
  riskLevel: z.enum(['success', 'warning', 'critical']),
  vendor: z.string(),
});

export type DashboardInsightsLeftNavViewModel = z.infer<typeof DashboardInsightsLeftNavViewModelSchema>;

export const DashboardInsightsVendorViewModelSchema = z.object({
  vendor: z.string(),
  insights: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  success: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  warning: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  critical: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
});

export type DashboardInsightsVendorViewModel = z.infer<typeof DashboardInsightsVendorViewModelSchema>;

export const DashboardInsightsViewModelSchema = z.object({
  leftNav: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  success: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  warning: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  critical: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  vendors: z.array(DashboardInsightsVendorViewModelSchema).default([]),
});

export type DashboardInsightsViewModel = z.infer<typeof DashboardInsightsViewModelSchema>;

export function dashboardInsightsModelToViewModel(input: unknown) {
  const model = DashboardInsightsModelSchema.parse(input);

  const headers: Record<DashboardInsightsLeftNavHeaderKeys, { index: number; type: ClickHouseDataHeadingType } | null> =
    {
      insight: null,
      last_seen: null,
      query_variables: null,
      title: null,
      category_friendly_name: null,
      report_summary_name: null,
      risk_level: null,
    };

  model[leftNavId]?.headers?.forEach((header, i) => {
    headers[header.name] = {
      index: i,
      type: ClickHouseParseDataHeadingType(header.type),
    };
  });

  if (headers.title === null) throw new Error('Mismatched headers');
  if (headers.insight === null) throw new Error('Mismatched headers');
  if (headers.last_seen === null) throw new Error('Mismatched headers');
  if (headers.query_variables === null) throw new Error('Mismatched headers');
  if (headers.category_friendly_name === null) throw new Error('Mismatched headers');
  if (headers.report_summary_name === null) throw new Error('Mismatched headers');
  if (headers.risk_level === null) throw new Error('Mismatched headers');

  const data: DashboardInsightsLeftNavViewModel[] = [];
  const dataSuccess: DashboardInsightsLeftNavViewModel[] = [];
  const dataWarning: DashboardInsightsLeftNavViewModel[] = [];
  const dataCritical: DashboardInsightsLeftNavViewModel[] = [];

  const vendors: Record<string, Omit<DashboardInsightsVendorViewModel, 'vendor'>> = {};

  for (const row of model[leftNavId]?.data ?? []) {
    const model: Record<DashboardInsightsLeftNavHeaderKeys, unknown> = {
      title: row[headers.title.index],
      insight: row[headers.insight.index],
      last_seen: row[headers.last_seen.index],
      query_variables: row[headers.query_variables.index],
      category_friendly_name: row[headers.category_friendly_name.index],
      report_summary_name: row[headers.report_summary_name.index],
      risk_level: row[headers.risk_level.index],
    };

    if (
      typeof model.title !== 'string' ||
      typeof model.insight !== 'string' ||
      typeof model.last_seen !== 'string' ||
      typeof model.category_friendly_name !== 'string' ||
      typeof model.report_summary_name !== 'string' ||
      typeof model.risk_level !== 'string' ||
      typeof model.query_variables !== 'object' ||
      model.query_variables === null
    )
      continue;

    try {
      const timezone = headers.last_seen.type.args[0]?.type ?? 'UTC';
      const viewModel: DashboardInsightsLeftNavViewModel = {
        title: model.title,
        id: LzString.compressToEncodedURIComponent(
          JSON.stringify({ insight: model.insight, ...model.query_variables })
        ),
        time: moment.tz(model.last_seen, timezone).local().subtract(1, 'second'),
        category: model.category_friendly_name,
        riskLevel:
          model.risk_level === 'warning' ? 'warning' : model.risk_level === 'critical' ? 'critical' : 'success',
        vendor: model.report_summary_name,
        // tagId:
        //   'tag_id' in model.query_variables && typeof model.query_variables?.tag_id === 'string'
        //     ? model.query_variables.tag_id
        //     : undefined,
      };

      vendors[model.report_summary_name] = vendors[model.report_summary_name] ?? {
        insights: [],
        success: [],
        warning: [],
        critical: [],
      };

      data.push(viewModel);
      vendors[model.report_summary_name].insights.push(viewModel);

      switch (viewModel.riskLevel) {
        case 'warning':
          dataWarning.push(viewModel);
          vendors[model.report_summary_name].warning.push(viewModel);
          break;
        case 'critical':
          dataCritical.push(viewModel);
          vendors[model.report_summary_name].critical.push(viewModel);
          break;
        case 'success':
          dataSuccess.push(viewModel);
          vendors[model.report_summary_name].success.push(viewModel);
          break;
      }
    } catch {}
  }

  const viewModel: DashboardInsightsViewModel = {
    leftNav: data,
    success: dataSuccess,
    warning: dataWarning,
    critical: dataCritical,
    vendors: Object.entries(vendors).map(([vendor, insights]) => ({ vendor, ...insights })),
  };

  return viewModel;
}
