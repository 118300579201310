import { Public, Rule, Token } from '@mui/icons-material';
import React from 'react';

type MiddlewareEventOptions = {
  name: string;
  namespace: string;
  path: string;
  method?: string;
  parents?: (string | undefined)[];
  source?: 'network' | 'middleware' | 'validate';
  status?: 'initialized' | 'processing' | 'completed' | 'failed';
  data?: any;
};

export class MiddlewareEventHandler {
  private static events: MiddlewareEvent[] = [];

  public static getEvents() {
    return this.events;
  }

  public static addEvent(event: MiddlewareEvent) {
    this.events.push(event);
  }
}

export class MiddlewareEvent {
  public static handler = MiddlewareEventHandler;
  path: MiddlewareEventOptions['path'];
  method: MiddlewareEventOptions['method'];
  namespace: MiddlewareEventOptions['namespace'];
  name: MiddlewareEventOptions['name'];
  source: MiddlewareEventOptions['source'];
  status: MiddlewareEventOptions['status'];
  parents?: MiddlewareEventOptions['parents'];
  data?: any;

  // results
  resultText?: string;

  private readonly _id: string;
  private readonly _created: Date = new Date();

  constructor({ namespace, name, path, source, status, method, parents, data }: MiddlewareEventOptions) {
    this._id = window.crypto.randomUUID();
    this.path = path;
    this.namespace = namespace;
    this.name = name;
    this.method = method;
    this.status = status ?? 'initialized';
    this.source = source ?? 'middleware';
    this.parents = parents ? parents.filter((x) => x !== undefined) : [];
    this.data = data;

    MiddlewareEvent.handler.addEvent(this);
  }

  get id() {
    return this._id;
  }

  get created() {
    return this._created;
  }

  toString() {
    let sourceFriendly = '';
    switch (this.source) {
      case 'middleware':
        sourceFriendly = 'Middleware';
        break;
      case 'network':
        sourceFriendly = 'Network';
        break;
      case 'validate':
        sourceFriendly = 'Validate';
        break;
    }
    return `${sourceFriendly}, ${this.namespace} ${this.name}`;
  }

  uiGetAction() {
    let verbString = this.path;
    if (this.method) {
      verbString = `${this.method} ${verbString}`;
    }
    if (this.resultText) {
      verbString += ` - ${this.resultText}`;
    }
    return verbString;
  }

  uiGetIcon() {
    switch (this.source) {
      case 'middleware':
        return <Token />;
      case 'network':
        return <Public />;
      case 'validate':
        return <Rule />;
    }
  }

  uiGetStatus() {
    switch (this.status) {
      case 'initialized':
        return 'Initialized';
      case 'processing':
        return 'Processing';
      case 'completed':
        return 'Completed';
      case 'failed':
        return 'Failed';
    }
  }
}

type MiddlewareErrorOptions = {
  cause: MiddlewareError | Error;
  viewType?: string;
  event: MiddlewareEvent;
};

export class MiddlewareError extends Error {
  viewType: string;
  event: MiddlewareEvent;
  constructor({ cause, viewType, event }: MiddlewareErrorOptions) {
    super('');
    this.name = 'ViewError';

    this.cause = cause;
    this.event = event;
    this.viewType = viewType ?? 'page';
  }
}

window.MiddlewareEventHandler = MiddlewareEventHandler;
window.MiddlewareEvent = MiddlewareEvent;
window.MiddlewareError = MiddlewareError;
