import Axios, { AxiosError, AxiosInstance } from 'axios';
import React, { createContext, ReactNode, startTransition, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { baseURL } from '../../config/api-urls';
import { AppRoutes } from '../../config/app-routes';
import useNotify from '../../hooks/useNotify';
import Label from '../../label/en-US';

const baseAxios = Axios.create({
  baseURL,
  headers: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type': 'application/json',
  },
});

export const AxiosContext = createContext<AxiosInstance>(baseAxios);

export function AxiosProvider({ children }: { children?: ReactNode }) {
  const navigate = useNavigate();
  const { Alert } = useNotify();
  const axios = useMemo(() => {
    baseAxios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: Error | AxiosError) => {
        if (Axios.isAxiosError(error)) {
          const { response: { status, statusText, data } = {} } = error;
          let customMessage;
          if (status === 401) {
            window.localStorage.removeItem('email');

            if (window.location.pathname !== AppRoutes.LOGIN) {
              startTransition(() => navigate(AppRoutes.LOGIN));
            }
            return Promise.reject(new Error('Authentication failed'));
          } else if (status === 405) {
            const {
              customResponse: { message, token },
            } = data as any;
            customMessage = message;
            if (window.location.pathname !== AppRoutes.SIGN_UP) {
              startTransition(() =>
                navigate({
                  pathname: AppRoutes.SIGN_UP,
                  search: `?token=${token}`,
                })
              );
              return Promise.reject(new Error(customMessage));
            }
          } else {
            Alert(Label.someThingWrong);
          }
          const errorText =
            customMessage || (data as any)?.message || statusText || error.message || 'INTERNAL SERVER ERROR';

          return Promise.reject(new Error(errorText));
        } else {
          Alert(Label.someThingWrong);
        }
      }
    );

    return baseAxios;
    // eslint-disable-next-line
  }, []);

  return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>;
}
