import { z } from 'zod';

// +---------+
// | Request |
// +---------+

const ClickHouseApiStringDateSchema = z.string().regex(/^\d{4}-\d{2}-\d{2}$/);

export const ClickHouseApiQueryVariablesSchema = z.object({
  to_date: ClickHouseApiStringDateSchema,
  from_date: ClickHouseApiStringDateSchema,
  // database: z.string(),
  pageview_date: z.string().optional(),
  environment_guid: z.string().uuid(),
});

export type ClickHouseApiQueryVariables = z.infer<typeof ClickHouseApiQueryVariablesSchema>;

// +----------+
// | Response |
// +----------+

function ClickHouseApiHeaderResponseSchemaBuilder<U extends string, T extends Readonly<[U, ...U[]]>>(headers: T) {
  return z
    .array(
      z.object({
        name: z.enum<string, typeof headers>(headers),
        type: z.string(),
      })
    )
    .length(headers.length);
}

export function ClickHouseApiResponseSchemaBuilder<U extends string, T extends Readonly<[U, ...U[]]>>(headers: T) {
  const headersSchema = ClickHouseApiHeaderResponseSchemaBuilder(headers);

  return z.object({
    headers: headersSchema,
    data: z.array(z.array(z.any()).length(headers.length)),
  });
}

export const ClickHouseApiGenericResponseSchema = z.object({
  headers: z.array(
    z.object({
      name: z.string(),
      type: z.string(),
    })
  ),
  data: z.array(z.array(z.any())),
});

export type ClickHouseApiGenericResponse = z.infer<typeof ClickHouseApiGenericResponseSchema>;
