import { RouteListGetter } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

const getInsightsRoutes: RouteListGetter = (lazily, isExperimentEnabled) => {
  if (!isExperimentEnabled('page.insights')) return <></>;
  const InsightsPage = lazily('insights2');
  return <Route path="insights2l" element={<InsightsPage />} />;
};

export default getInsightsRoutes;
