import { useEffect, useRef } from 'react';

import { MiddlewareEvent } from '.';

export function useMiddlewareHookInit(
  namespace: string,
  path: string,
  parentIds: (string | undefined)[] = [],
  data?: any
) {
  const event = useRef<MiddlewareEvent | null>(null);

  useEffect(() => {
    event.current = new MiddlewareEvent({
      path,
      namespace,
      name: 'Middleware hook instance created',
      parents: parentIds,
      source: 'middleware',
      status: 'completed',
      data,
    });

    return () => {
      new MiddlewareEvent({
        path,
        namespace,
        name: 'Middleware hook instance destroyed',
        parents: [event.current?.id],
        source: 'middleware',
        status: 'completed',
      });
    };
  });

  return event.current;
}

export function useMiddlewareHookUpdate(
  namespace: string,
  path: string,
  parentIds: (string | undefined)[] = [],
  data?: any
) {
  return new MiddlewareEvent({
    path,
    namespace,
    name: 'Middleware hook instance state updated',
    parents: parentIds,
    source: 'middleware',
    status: 'processing',
    data,
  });
}
